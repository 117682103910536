<template>
    <p v-if="skin === 'noSkin'" class="col-12 text-center mt-5">
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" size="2xl" beat class="my-4" /><br /><br />
        We are sorry but something went wrong.
    </p>
    <dynamic componentPath="loader" folder="others" :addSkin="true" v-else-if="showloader === true || skin === null || configStore.config === null" />
    <template v-else>
        <dynamic componentPath="_ac" folder="skins" v-if="skin === 'ac'" />
        <dynamic componentPath="_lj" folder="skins" v-if="skin === 'lj'" />
        <dynamic componentPath="_bo" folder="skins" v-else-if="skin === 'bo'" />
        <dynamic componentPath="_sp" folder="skins" v-else-if="skin === 'sp'" />
        <dynamic componentPath="_ab" folder="skins" v-else-if="skin === 'ab'" />
    </template>
</template>

<script>
import { getCookie, createCookie } from "./helpers/helpers";
import { getContentVersion } from "./services/strapi/axiosStrapiRequests";

export default {
    name: "App",
    data: function () {
        return {
            showloader: true,
            skin: null,
            initialCallFired: false,
            contentVersionCheckDone: false,
        };
    },
    created: function () {
        //Getting required data
        this.skin = this.getSkin();
        if (this.skin !== "noSkin") {
            this.configStore.initConfig(this.skin);

            getContentVersion(this.myStore, this.skin).then((latestVersion) => {
                const currentVersion = getCookie("contentVersion");
                if (currentVersion === "" || currentVersion !== `${latestVersion}`) {
                    // Clear persisted calls store data
                    createCookie("contentVersion", latestVersion, 10);
                    this.perCallsStore.$reset();
                    this.configStore.$reset();
                }
                this.contentVersionCheckDone = true;
                this.configStore.initConfig(this.skin);
                this.$forceUpdate();
            });
        }
    },
    updated: function () {
        if (this.initialCallFired === false && this.contentVersionCheckDone) {
            // getDictionaries(this.perCallsStore, this.myStore);
            // getPages(this.perCallsStore, this.skin, this.$router, this.myStore);

            this.showloader = false;
            this.initialCallFired = true;
        }
    },
    methods: {
        getSkin() {
            if (document.location.href.indexOf("purplebay") !== -1) {
                return "ac";
            } else if (document.location.href.indexOf("cyberelite") !== -1) {
                return "bo";
            } else if (document.location.href.indexOf("primeweb") !== -1) {
                return "lj";
            } else if (document.location.href.indexOf("spop") !== -1) {
                return "sp";
            } else if (document.location.href.indexOf("aboveandbeyond") !== -1) {
                return "ab";
            } else if (document.location.href.indexOf("localhost") !== -1 || document.location.href.indexOf("bs-local") !== -1) {
                return "lj";
            } else {
                return "noSkin";
            }
        },
    },
};
</script>
