import { defineStore } from "pinia";
import { defineComponent } from "vue";

const skins = {
    ac: {
        websiteType: "affiliate",
        affiliateCompanyName: "Purple Bay Affiliates",
        affiliateLink: "https://login.purplebayaffiliates.com/signup.php",
        originalDomain: "www.amoncasino.com",
        originalName: "Amon Casino",
        coolName: "Amon",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        testimonials: [
            {
                website: "https://www.nyecasino.me",
                comment: "We are really happy working with PurpleBay Affiliates and it is probably one of the strongest programs out there for online casinos. We are seeing great conversion and good player value.",
            },
            {
                website: "https://www.nettcasino.com",
                comment: "I can only recommend working with PurpleBay Affiliates, they have truly strong brands. We are looking forward to see this growing into a mutual beneficial partnership. They have always been a great affiliate managers from earlier partnerships.",
            },
            {
                website: "https://www.norskeautomater.com",
                comment: "We are really happy to be working with PurpleBay Affiliates, their conversion is strong and they have proven great player values during the period we have promoted them. The team is serviceminded and always available to help.",
            },
            {
                website: "https://www.kryptocasino.me",
                comment: "We love PurpleBay Affiliates, they have some of the best cryptocasinos at the moment, we can only recommend it.",
            },
            {
                website: "https://www.crypto-casinos.com",
                comment: "We love the products from PurpleBay Affiliates, they have great converting brands that have proven to work from several of our sources.",
            },
            {
                website: "https://www.casinoavecvisa.com",
                comment: "This has been a game-changer for our business. The Purple Bay Affiliates offers excellent commission structures, timely payments, and top-notch support, making the partnership smooth and profitable. The brands under their umbrella are high-converting and reliable, and the Affiliate Manager behind is always ready to assist with any queries or marketing needs.",
            },
            {
                website: "https://dk.topcasinoer.net/",
                comment: "We appreciate working with Purplebayaffiliates. The team gives us great support, and their brands are good for players.",
            },
            {
                website: "https://sportego.ie/",
                comment: "We have been working with the team behind Purplebay affiliates for a long time, and truly trust that this will be a great partnership. ",
            },
            {
                website: "https://nogamstopcasinos.org.uk",
                comment:
                    "PurpleBay stands out as a trusted affiliate program in the online gaming industry, offering an impressive variety of slots and table games to cater to diverse preferences. Its intuitive interface ensures a seamless user experience, accommodating both new and seasoned players alike. With a solid reputation in the industry, The program also boasts exceptional Affiliate Managers who are responsive, professional, and committed to fostering a productive and rewarding partnership.",
            },
            {
                website: "https://iars.org.uk",
                comment: "As an emerging leader in online casinos, PurpleBay captivates players with its attractive bonuses and promotions, designed to maximize satisfaction and encourage loyalty. Their dedication to providing value and delivering a superior gaming experience places them among the top choices for both casual and frequent players, ensuring an enjoyable and worthwhile experience.",
            },
            {
                website: "https://nongamstopbookies.com",
                comment:
                    "PurpleBay's customer support services are second to none, offering timely and effective assistance via live chat and email. Complementing this is the reliability of their Affiliate Managers, who work diligently to address concerns and ensure smooth communication. The detailed FAQ section further empowers players to find answers quickly, underscoring their commitment to a hassle-free experience.",
            },
            {
                website: "https://stjamestheatre.co.uk",
                comment: "Security and fairness are core to PurpleBay’s operations, with cutting-edge encryption technology safeguarding transactions and player data. Regular audits ensure games adhere to strict fairness standards, fostering user confidence. This focus on transparency and integrity makes PurpleBay a trusted and secure choice for online gaming enthusiasts.",
            },
            {
                website: "https://vso.org.uk",
                comment:
                    "PurpleBay excels in its affiliate program, offering competitive commissions, prompt payments, and comprehensive support. A standout feature is the unparalleled support provided by the Affiliate Managers, who are proactive, approachable, and deeply invested in their affiliates' success. Whether it’s assistance with strategy or providing timely insights, the program ensures a rewarding partnership while maintaining flexibility for affiliates to thrive in the dynamic gaming market.",
            },
            {
                website: "https://casinosclub.com",
                comment: "Our experience with Betzino Casino has been outstanding. Their dedication to providing a top-tier gaming experience has made our partnership incredibly successful. We’ve seen great results, and we look forward to continuing this rewarding journey together. ",
            },
        ],
    },
    bo: {
        websiteType: "affiliate",
        affiliateCompanyName: "Cyber Elite Affiliates",
        affiliateLink: "https://affiliates.cybereliteaffiliates.com/signup.php",
        originalDomain: "www.bofcasino.com",
        originalName: "Bof Casino",
        coolName: "Bof",
        companyName: "Elite Cyber Services Limited",
        licenceCountry: "Belize",
        registrationNumber: "177058",
        registrationAddress: "123 Barrack Road, Belize City, Belize",
        testimonials: [
            {
                website: "https://www.casinovanger.com",
                comment: "Bof casino is simply one of the most trustworthy companies out there, and we are saying this as a long-time business partner. If you’re an affiliate that wants to work with a solid business, Bof casino is your brand!",
            },
            {
                website: "https://www.nyacasino.se",
                comment: "Cyber Elite Affiliates have been very supportive right from the first point of contact. The professional approach is always clearly visible, making it a no-brainer to add their casino to our site.",
            },
            {
                website: "https://www.apparata.net/casino/",
                comment: "Working with CyberElite Affiliates has been a seamless experience for the team at apparata.net . Their professionalism and friendly team has been extremly helpful. We are super happy working with them and look forward to continuing our partnership.",
            },
            {
                website: "https://www.buitenlandseonlinecasinos.org",
                comment:
                    "Cyber Elite Affiliates has been an incredible experience for us. We appreciate their approach, which helped us achieve new success levels. Their timely payments and excellent management skills set them apart from other affiliate programs. With their help, we could identify and overcome our weaknesses and turn them into strengths. We highly recommend Cyber Elite Affiliates to anyone looking for a reliable and effective affiliate program.",
            },
            {
                website: "https://casinohallen.se/casino/utlandska/",
                comment: "We have been cooperating with CyberElite affiliates for a while, and we are very impressed with the partnership. They have the best Account Managers in their team, a great platform and payments are always issued. Casinohallen fully recommends Cyberelite!",
            },
            {
                website: "https://www.casinoszondercruksonline.com",
                comment: "Cyber Elite Affiliates has been an amazing partner for us. Their creativity and originality are what set them apart from other affiliate programs. This reliable company offers customers excellent support and quick competent answers at any time. Thanks to the individual approach, they can analyze every detail that directly affects the success of our website.",
            },
            {
                website: "https://www.casinoutanspelpaus.io/",
                comment: "CyberElite affiliates has become an integral part of our growth. Their great attention to detail and strong dedication to our mutual success have made a significant impact on us at casinoutanspelpaus.",
            },
            {
                website: "https://www.onlinecasinoszonderlicentie.com",
                comment:
                    "Cyber Elite Affiliates has been instrumental in helping us achieve our goals. With their management tools, we got the long-awaited results we sought. Their qualified managers are always available to process requests and work out strategies to solve any problems we may encounter. Their effective service package has helped us make our site the best. We highly recommend Cyber Elite Affiliates to anyone looking for a reliable and effective affiliate program.",
            },
            {
                website: "https://www.sloterman-nl.com",
                comment: "Cyber Elite Affiliates has been an amazing partner for us. Their creativity and originality are what set them apart from other affiliate programs. This reliable company offers customers excellent support and quick competent answers at any time. Thanks to the individual approach, they can analyze every detail that directly affects the success of our website.",
            },
            {
                website: "https://www.onlinecasinometvolt.com",
                comment:
                    "Cyber Elite Affiliates has been an incredible experience for us. We appreciate their approach, which helped us achieve new success levels. Their timely payments and excellent management skills set them apart from other affiliate programs. With their help, we could identify and overcome our weaknesses and turn them into strengths. We highly recommend Cyber Elite Affiliates to anyone looking for a reliable and effective affiliate program.",
            },
            {
                website: "https://www.safecasinoshub.com",
                comment: "Cyber Elite Affiliates is a great addition to our partners team, working with them is an amazing experience, they are always willing to help you and improving.",
            },
            {
                website: "https://www.popmani.se/",
                comment: "Cyber Elite Affiliates are great to work with. Their affiliate managers are committed and help new affiliate members. We are very happy to cooperate with the Cybereliteaffiliates affiliate team and their brand.",
            },
            {
                website: "https://www.casinoutanspelpaus.io",
                comment: "Working with CyberElite Affiliates has been a game-changer for us at CasinoutanSpelpaus.io. Their team is incredibly professional and always available to assist with any needs. The deals and promotions they offer are top-notch. We highly recommend working with them!",
            },
        ],
    },
    lj: {
        websiteType: "affiliate",
        affiliateCompanyName: "Prime Web Affiliates",
        affiliateLink: "https://affiliates.primewebaffiliates.com/signup.php",
        originalDomain: "www.letsjackpot.com",
        originalName: "Lets Jackpot",
        coolName: "Lets Jackpot",
        companyName: "Prime Web OPS SRL",
        licenceCountry: "Anjouan",
        registrationNumber: "3-102-904748",
        registrationAddress: "PROVINCE 01 SAN JOSE, CANTON 08 GOICOECHEA, CALLE BLANCOS, MONTELIMAR, Costa Rica",
        testimonials: [
            {
                website: "https://casinospotfr.com/",
                comment: "Guys, you are simply the best! We have been with you for a long time and always know that we can rely on you. Your support, creative solutions, and willingness to help in any situation make you not just partners but real friends. Every new project with you turns into a pleasure. Thank you for being there!",
            },
            {
                website: "https://casinoenligneguru.com/",
                comment:"We work in a field where support is everything, so finding PrimeWeb Affiliates was a real gift. Their team is always available, ready to answer questions, and even offer advice if something is unclear. When we first started working with them, we didn't expect to receive not just services but genuine help in implementing our ideas. Every interaction with their specialists leaves us confident that the project is in good hands. This is a rarity that we truly value.",
            },
            {
                website: "https://nl.onlinecasinosspelen.com/",
                comment: "Partnering with PrimeWeb Affiliates has been a game-changer. Their top-notch support, timely payouts, and excellent offers have significantly boosted our revenue. A reliable and professional team, every step of the way.",
            },
            {
                website: "https://casinotop3.org/",
                comment: "PrimeWeb Affiliates provides an outstanding partnership experience. Their user-friendly platform, detailed reporting, and consistent commissions make it easy to succeed. Highly recommended for anyone in the casino industry.",
            },
            {
                website: "https://nieuwe-casinos.net/",
                comment: "Working with PrimeWeb Affiliates is a pleasure. Their diverse offers and competitive commissions have consistently driven strong conversions. Plus, their dedicated team is always ready to help. Truly a top-tier partner.",
            },
            {
                website: "https://casinozonderregistratie.net/",
                comment: "PrimeWeb Affiliates exceeded our expectations. Their transparent communication, generous rewards, and quick payouts make them a standout affiliate network. A trusted partner that genuinely values its affiliates' success.",
            },
            {
                website: "https://zondercruks.net/",
                comment: "Our collaboration with PrimeWeb Affiliates has been seamless. Their innovative marketing tools and exceptional support team have made our campaigns incredibly effective. They are truly committed to affiliate success.",
            },
            {
                website: "https://casinovanger.com",
                comment: "Our collaboration with LetsJackpot has been truly exceptional. They boast a skilled team always ready to assist, ensuring smooth communication at all times. Their captivating gaming platform is highly preferred by our players. VRS wholeheartedly endorses LetsJackpot.",
            },
        ],
    },
    sp: {
        websiteType: "company",
        companyName: "SPOP OPS SOCIEDAD DE RESPONSABILIDAD LIMITADA",
        licenceCountry: "Costa Rica",
        registrationNumber: "4062001322405.00",
        registrationAddress: "San Miguel de Santo Domingo de Heredi, 40303, Santo Domingo, Heredia, Costa Rica",
        email: "admin@spopops.com",
    },
    ab: {
        websiteType: "company",
        companyName: "Above & Beyond IS",
        licenceCountry: "Limassol Cyprus",
        registrationNumber: "HE357005",
        registrationAddress: "Georgiou Katsounotou, 6, 3036, Limassol Cyprus",
        email: "finance@aboveandbeyondintegratedsolutions.com",
        contactNumber: "+35722053893",
    },
};

export const useConfigStore = defineStore("config", {
    state: () => ({
        config: null,
    }),
    actions: {
        initConfig(skin) {
            const initialConfigObject = {
                skin,
                websiteType: skins[skin].websiteType,
                affiliateCompanyName: skins[skin].affiliateCompanyName,
                affiliateLink: skins[skin].affiliateLink,
                originalDomain: skins[skin].originalDomain,
                originalName: skins[skin].originalName,
                coolName: skins[skin].coolName,
                companyName: skins[skin].companyName,
                licenceCountry: skins[skin].licenceCountry,
                registrationNumber: skins[skin].registrationNumber,
                registrationAddress: skins[skin].registrationAddress,
                testimonials: skins[skin].testimonials,
                email: skins[skin].email,
                contactNumber: skins[skin].contactNumber,
            };
            this.config = Object.assign({}, this.config, initialConfigObject);
        },
    },
    persist: true,
});
